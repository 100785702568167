import React from 'react'
import { Link } from 'gatsby'
import logoColor from '../../assets/images/icons/logo-color-lg.png'
import bbbLogo from '../../assets/images/icons/bbb-logo.png'

const FooterContent = ({ companyDetails }) => {
    const {
        acf_companyDetails: { address, email, phone },
    } = companyDetails

    return (
        <>
            <Link className="footer__logo-link" to="/">
                <img
                    className="footer__logo"
                    src={logoColor}
                    alt="TEKFIRM blue logo"
                />
            </Link>
            <a
                className="footer__logo-link"
                target="_blank"
                rel="noreferrer"
                href="https://www.bbb.org/us/fl/miami/profile/information-technology-services/tekfirm-llc-0633-90430780"
            >
                <img
                    className="footer__bbb-logo"
                    src={bbbLogo}
                    alt="Accredited business logo"
                />
            </a>
            <div className="footer__contact-box">
                <a className="footer__phone" href="tel:+17864721788">
                    {phone}
                </a>
                <a className="footer__email" href={`mailto:${email}`}>
                    {email}
                </a>
                <a
                    href={`http://maps.google.com/?q=${
                        address.line1 + address.line2
                    }`}
                    target="_blank"
                    rel="noreferrer"
                    className="footer__address"
                >
                    {address.line1 ?? ''}
                    <br />
                    {address.line2 ?? ''}
                </a>
            </div>
        </>
    )
}

export default FooterContent
