import React, { useState } from 'react'
import PropTypes from 'prop-types'
import useForm from '../../utils/useForm'
import { navigate } from 'gatsby'

const JoinTheTeamForm = ({ formTitle, complianceMessage }) => {
    const [file, setFile] = useState(null)
    const [fileLabel, setInputLabel] = useState('Resume / CV')
    const [disable, setDisable] = useState(false)
    const [message, setMessage] = useState('')
    const { values, updateValue, resetValues } = useForm({
        firstName: '',
        lastName: '',
        phone: '',
        email: '',
        position: '',
    })

    const handleInputChange = e => {
        let fileName = e.target.files.length
            ? e.target.files[0].name
            : 'Resume / CV'
        fileName =
            fileName.length > 30 ? fileName.substr(0, 30 - 1) + '...' : fileName
        setInputLabel(fileName)
        setFile(e.target.files[0])
    }

    const handleSubmit = async e => {
        e.preventDefault()
        setDisable(true)
        setMessage('')

        if (!file) {
            setMessage('Please Attach your resume.')
        } else {
            let formData = new FormData()
            formData.append('firstName', values.firstName)
            formData.append('lastName', values.lastName)
            formData.append('phone', values.phone)
            formData.append('email', values.email)
            formData.append('position', values.position)
            formData.append('resume', file)

            const res = await fetch(
                `${process.env.GATSBY_EMAIL_HANDLER_URL}/api/career-form`,
                {
                    method: 'POST',
                    body: formData,
                }
            )
            // check if everything worked
            if (res.status >= 400 && res.status < 600) {
                setDisable(false)
                setMessage(
                    'Oops! Something went wrong. Please try again later or email us at info@tekfirm.com'
                )
            } else {
                resetValues()
                setFile()
                setDisable(false)
                navigate('/thank-you')
            }
        }
    }

    return (
        <form
            className="careers-form"
            id="careers-form"
            method="POST"
            encType="multipart/form-data"
            onSubmit={handleSubmit}
        >
            <h1 className="headline headline--h2 careers-form__title">
                {formTitle}
            </h1>
            <div className="careers-form__container">
                <input
                    type="text"
                    name="firstName"
                    className="careers-form__input"
                    placeholder="First Name"
                    required
                    value={values.firstName}
                    onChange={updateValue}
                />
                <input
                    type="text"
                    name="lastName"
                    className="careers-form__input"
                    placeholder="Last Name"
                    required
                    value={values.lastName}
                    onChange={updateValue}
                />
                <input
                    type="email"
                    name="email"
                    className="careers-form__input"
                    placeholder="Email"
                    required
                    value={values.email}
                    onChange={updateValue}
                />
                <input
                    type="tel"
                    name="phone"
                    className="careers-form__input"
                    placeholder="Phone Number"
                    required
                    value={values.phone}
                    onChange={updateValue}
                />
                <input
                    type="text"
                    name="position"
                    className="careers-form__input"
                    placeholder="Desired Position"
                    required
                    value={values.position}
                    onChange={updateValue}
                />
                <label
                    htmlFor="careers-resume-upload"
                    className="careers-form__input careers-form__input--upload-label"
                >
                    <span>{fileLabel}</span>
                    <div className="icon icon--attachment" />
                </label>
                <input
                    type="file"
                    onChange={handleInputChange}
                    id="careers-resume-upload"
                    name="resume"
                    className="careers-form__input careers-form__input--upload"
                    placeholder="Attach Resume/CV"
                    accept=".doc,.docx,.pdf"
                />
            </div>
            <p className="careers-form__error-message"> {message}</p>
            <p className="paragraph paragraph--dark careers-form__message">
                {complianceMessage}
            </p>
            <button className="careers-form__submit">SUBMIT APPLICATION</button>
        </form>
    )
}

JoinTheTeamForm.propTypes = {}

export { JoinTheTeamForm }
