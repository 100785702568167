import React from 'react'
import PropTypes from 'prop-types'
import htmr from 'htmr'
import Img from 'gatsby-image'

import { Section } from '../Common'

const ContentSection = ({ title, description, image }) => {
    console.log('image', image)
    return (
        <Section className="top-grey-section">
            
                <div className="top-grey-sec-wrap">
                    <div className='img-container'><Img fluid={image} className="" alt="img" /></div>
                    <div className="top-grey-sec-para">
                    <h2 className="">{htmr(title)}</h2>
                        <p className="">{htmr(description)}</p>
                    </div>
                </div>
            
        </Section>
    )
}

ContentSection.propTypes = {
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
}

export { ContentSection }
