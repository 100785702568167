import { graphql, useStaticQuery } from 'gatsby'

function useBookConsultation() {
    const data = useStaticQuery(
        graphql`
            query BookConsultationQuery {
                wpgraphql {
                    pageBy(uri: "book-consultation") {
                        title
                        acf_hero {
                            bbDescription
                            bbTitle
                            heroImage {
                                altText
                                sourceUrl
                                sourceUrlSharp {
                                    childImageSharp {
                                        fluid(quality: 100, maxWidth: 1440) {
                                            base64
                                            aspectRatio
                                            src
                                            srcSet
                                            sizes
                                        }
                                    }
                                }
                            }
                        }
                        acf_bookConsultationContent {
                            mainDescription
                            header
                            process {
                                icon
                                title
                                description
                            }
                            footerSection {
                                description
                                includes {
                                    item
                                }
                            }
                            form {
                                title
                                description
                            }
                        }
                    }
                }
            }
        `
    )
    return data.wpgraphql.pageBy
}

export { useBookConsultation }
