import { graphql, useStaticQuery } from 'gatsby'

function useContactUs() {
    const data = useStaticQuery(
        graphql`
            query ContactUsQuery {
                wpgraphql {
                    pageBy(uri: "contact-us") {
                        title
                        acf_hero {
                            heroImage {
                                altText
                                sourceUrl
                                sourceUrlSharp {
                                    childImageSharp {
                                        fluid(quality: 100, maxWidth: 1440) {
                                            base64
                                            aspectRatio
                                            src
                                            srcSet
                                            sizes
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        `
    )
    return data.wpgraphql.pageBy
}

export { useContactUs }
