import React from 'react'
import PropTypes from 'prop-types'
import htmr from 'htmr'
import { Section } from '.'

const TestimonialCard = ({ title, description, position, logo }) => {
    return (
        <Section className="testimonial-col">
            <div className="testimonial-para">
                <p>{htmr(description)}</p>
            </div>
            <div className="testimonial-details">
                {/* <div className='profile-img'> <img src={logo} alt="logo" /></div> */}
                <div className='profile-name'><h3>{title}</h3>
                <label>{position}</label></div>
                
            </div>
           
        </Section>
    )
}

TestimonialCard.propTypes = {
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    position: PropTypes.string.isRequired,
}

export { TestimonialCard }
