import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { navigate } from 'gatsby'
import DayPickerInput from 'react-day-picker/DayPickerInput'
import 'react-day-picker/lib/style.css'
import MomentLocaleUtils, {
    formatDate,
    parseDate,
} from 'react-day-picker/moment'

import useForm from '../../utils/useForm'
import htmr from 'htmr'

const BookConsultationForm = ({ title, description }) => {
    const [consultationDate, setConsultationDate] = useState('')
    const [disable, setDisable] = useState(false)
    const [message, setMessage] = useState('')
    const { values, updateValue, resetValues } = useForm({
        name: '',
        number: '',
        companyName: '',
        email: '',
        industry: '',
    })

    const handleSubmit = async e => {
        e.preventDefault()
        setDisable(true)
        const body = {
            name: values.name,
            number: values.number,
            companyName: values.companyName,
            email: values.email,
            industry: values.industry,
            consultationDate: consultationDate,
        }
        const res = await fetch(
            `${process.env.GATSBY_EMAIL_HANDLER_URL}/api/book-consultation-form`,
            {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(body),
            }
        )
        // check if everything worked
        if (res.status >= 400 && res.status < 600) {
            setDisable(false)
            setMessage(
                'Oops! Something went wrong. Please try again later or email us at info@tekfirm.com'
            )
        } else {
            resetValues()
            setDisable(false)
            navigate('/thank-you')
        }
    }
    return (
        <form className="consultation-form" onSubmit={handleSubmit}>
            <h1 className="headline headline--h2 consultation-form__title">
                {htmr(title)}
            </h1>
            <p className="paragraph paragraph--dark paragraph--md paragraph--bold consultation-form__description">
                {htmr(description)}
            </p>
            <div className="consultation-form__container">
                <input
                    type="text"
                    name="name"
                    className="consultation-form__input"
                    placeholder="Name"
                    required
                    onChange={updateValue}
                    value={values.name}
                />
                <input
                    type="tel"
                    name="number"
                    className="consultation-form__input"
                    placeholder="Phone Number"
                    required
                    onChange={updateValue}
                    value={values.number}
                />
                <input
                    type="email"
                    name="email"
                    className="consultation-form__input"
                    placeholder="Email"
                    required
                    onChange={updateValue}
                    value={values.email}
                />
                <input
                    type="text"
                    name="companyName"
                    className="consultation-form__input"
                    placeholder="Company Name"
                    required
                    onChange={updateValue}
                    value={values.companyName}
                />
                <input
                    type="text"
                    name="industry"
                    className="consultation-form__input"
                    placeholder="Industry"
                    required
                    onChange={updateValue}
                    value={values.industry}
                />
                <DayPickerInput
                    onDayChange={day => setConsultationDate(formatDate(day))}
                    formatDate={formatDate}
                    parseDate={parseDate}
                    placeholder="Consultation Date"
                />
            </div>
            <p className="consultation-form__error-message">{message}</p>
            <button className="consultation-form__submit">
                BOOK CONSULTATION
                <div className="icon icon--arrow-right icon--white" />
            </button>
        </form>
    )
}

BookConsultationForm.propTypes = {}

export { BookConsultationForm }
