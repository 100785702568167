import React, { useState, useRef, useEffect } from 'react'
import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock'
import { Link } from 'gatsby'
import { Container } from '../Common'
import FooterSocial from '../Footer/FooterSocial'
import logoWhite from '../../assets/images/icons/logo-white-lg.png'
import logoColor from '../../assets/images/icons/logo-color-lg.png'
import { useHomePage } from '../../GraphQL/Queries'

const Nav = ({ noHero }) => {
    const { services } = useHomePage()
    const navRef = useRef()
    const navRefScroll = useRef()
    const [navBackground, setNavBackground] = useState(false)
    const [toggleSubMenu, setSubMenu] = useState({})
    navRef.current = navBackground

    const subMenuToggle = e => {
        setSubMenu({
            [e.target.getAttribute('data-name')]: !toggleSubMenu[
                e.target.getAttribute('data-name')
            ],
        })
    }

    const toggleScroll = e => {
        if (e.target.checked) disableBodyScroll(navRefScroll.current)
        else clearAllBodyScrollLocks()
    }

    useEffect(() => {
        const handleScroll = () => {
            const show = window.scrollY > 0
            if (navRef.current !== show) {
                setNavBackground(show)
            }
        }
        document.addEventListener('scroll', handleScroll)
        return () => {
            document.removeEventListener('scroll', handleScroll)
            clearAllBodyScrollLocks()
        }
    }, [])

    return (
        <header
            className={`header ${
                navBackground || noHero ? 'header--scrolled' : ''
            }`}
            ref={navRefScroll}
        >
            <Container>
                <input
                    className="header__menu-toggle"
                    type="checkbox"
                    id="menu"
                    onChange={toggleScroll}
                />
                <label className="open" htmlFor="menu">
                    <i className="icon icon--menu-open icon--md" />
                </label>
                <div className="header__logo">
                    <Link to="/">
                        <img
                            src={logoWhite}
                            className="header__logo"
                            alt="TEKFIRM logo white"
                        />
                    </Link>
                </div>
                <div className="header__logo header__logo--color">
                    <Link to="/">
                        <img
                            className="header__logo header__logo--color"
                            src={logoColor}
                            alt="TEKFIRM logo blue"
                        />
                    </Link>
                </div>
                <nav className="header__mobile-menu">
                    <div className="header__mobile-top-bar">
                        <Link to="/">
                            <img
                                className="header__mobile-logo"
                                src={logoColor}
                                alt="TEKFIRM logo blue"
                            />
                        </Link>

                        <label className="close" htmlFor="menu">
                            <i className="icon icon--menu-close icon--md" />
                        </label>
                    </div>
                    <ul className="header__menu">
                        <li
                            className="header__menu-items"
                            onClick={subMenuToggle}
                        >
                            <a
                                className="header__menu-link dropdown"
                                data-name="aboutus"
                            >
                                ABOUT US
                            </a>
                            <ul
                                className={`header__menu-sub ${
                                    toggleSubMenu.aboutus
                                        ? 'header__menu-sub-show'
                                        : ''
                                }`}
                            >
                                <div className="header__menu-sub-items-wrapper">
                                    <li className="header__menu-sub-items">
                                        <Link
                                            to="/meet-the-team"
                                            className="header__menu-link"
                                        >
                                            Meet The Team
                                        </Link>
                                    </li>
                                    <li className="header__menu-sub-items">
                                        <Link
                                            to="/join-the-team"
                                            className="header__menu-link"
                                        >
                                            Join The Team
                                        </Link>
                                    </li>
                                    <li className="header__menu-sub-items">
                                        <Link
                                            to="/partners"
                                            className="header__menu-link"
                                        >
                                            partners
                                        </Link>
                                    </li>
                                    <li className="header__menu-sub-items">
                                        <Link
                                            to="/charities"
                                            className="header__menu-link"
                                        >
                                            Charities
                                        </Link>
                                    </li>
                                </div>
                            </ul>
                        </li>
                        <li
                            className="header__menu-items"
                            onClick={subMenuToggle}
                        >
                            <a
                                className="header__menu-link dropdown"
                                data-name="services"
                            >
                                services
                            </a>
                            <ul
                                className={`header__menu-sub ${
                                    toggleSubMenu.services
                                        ? 'header__menu-sub-show'
                                        : ''
                                }`}
                            >
                                <div className="header__menu-sub-items-wrapper">
                                    {services.edges.map((item, index) => (
                                        <li
                                            className="header__menu-sub-items"
                                            key={`nav-${index}`}
                                        >
                                            <Link
                                                to={`/${item.node.slug}`}
                                                className="header__menu-link"
                                            >
                                                {item.node.title}
                                            </Link>
                                        </li>
                                    ))}
                                    <li
                                        className="header__menu-sub-items"
                                        key={`nav`}
                                    >
                                        <Link
                                            to={`/cybersecurity-compliance`}
                                            className="header__menu-link"
                                        >
                                            Cybersecurity & Compliance
                                        </Link>
                                    </li>
                                </div>
                            </ul>
                        </li>
                        <li className="header__menu-items">
                            <Link to="/blog" className="header__menu-link">
                                Blog
                            </Link>
                        </li>

                        <li className="header__menu-items">
                            <Link
                                to="/contact-us"
                                className="header__menu-link"
                            >
                                Contact Us
                            </Link>
                        </li>
                        <li className="header__menu-items">
                            <a
                                className="header__menu-link"
                                target="_blank"
                                rel="noreferrer"
                                href="https://support.thetekfirm.com/login/join.jsp"
                            >
                                support
                            </a>
                        </li>
                    </ul>
                    <div className="header__mobile-footer">
                        <div className="divider divider--light" />
                        <FooterSocial
                            facebook="https://www.facebook.com/tekfirm/"
                            instagram="https://www.instagram.com/tekfirm/"
                            linkedIn="https://www.linkedin.com/company/tekfirm/"
                            twitter="https://twitter.com/thetekfirm"
                        />
                    </div>
                </nav>

                <div className="header__consultation">
                    <Link
                        to="/book-consultation"
                        className="header__consultation-button"
                    >
                        <i className="icon icon--handshake icon--md" />
                        BOOK CONSULTATION
                    </Link>
                    <Link
                        to="/book-consultation"
                        className="icon icon--handshake icon--white icon--lg header__consultation-icon"
                    ></Link>
                </div>
            </Container>
        </header>
    )
}

export default Nav
