import React from 'react'
import PropTypes from 'prop-types'

const Container = ({ className, children, fullBleed, compact, small }) => {
    const classes = {
        extra: className ? className : '',
        fullBleed: fullBleed ? 'container--full-bleed' : '',
        compact: compact ? 'container--compact' : '',
        small: small ? 'container--small' : '',
    }
    return (
        <div
            className={`container ${classes.extra} ${classes.fullBleed} ${classes.compact} ${classes.small}`}
        >
            {children}
        </div>
    )
}

Container.propTypes = {
    children: PropTypes.node.isRequired,
    className: PropTypes.string,
    fullBleed: PropTypes.bool,
    compact: PropTypes.bool,
    small: PropTypes.bool,
}

export { Container }
