import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Container } from '../Common'
import useForm from '../../utils/useForm'
import { navigate } from 'gatsby'
import Img from 'gatsby-image'
import htmr from 'htmr'

const CharitiesForm = ({ title, description, image, alt }) => {
    const [disable, setDisable] = useState(false)
    const [message, setMessage] = useState('')
    const { values, updateValue, resetValues } = useForm({
        firstName: '',
        lastName: '',
        number: '',
        email: '',
    })

    const handleSubmit = async e => {
        e.preventDefault()
        setDisable(true)
        const body = {
            firstName: values.firstName,
            lastName: values.lastName,
            number: values.number,
            email: values.email,
        }
        const res = await fetch(
            `${process.env.GATSBY_EMAIL_HANDLER_URL}/api/charities-form`,
            {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(body),
            }
        )
        // check if everything worked
        if (res.status >= 400 && res.status < 600) {
            setDisable(false)
            setMessage(
                'Oops! Something went wrong. Please try again later or email us at info@tekfirm.com'
            )
        } else {
            resetValues()
            setDisable(false)
            navigate('/thank-you')
        }
    }

    return (
        <div className="charities__contact-section">
            <Img
                fluid={image}
                className={'charities__contact-image'}
                alt={alt}
            />
            <Container>
                <div className="charities__contact-block">
                    <h2 className="headline headline--h2">{htmr(title)}</h2>
                    <p className="paragraph paragraph--lg charities__contact-block-description">
                        {htmr(description)}
                    </p>
                </div>
                <div className="charities__contact-block charities__contact-block--end">
                    <form
                        className="charities__form"
                        id="charitiesForm"
                        onSubmit={handleSubmit}
                    >
                        <div className="charities__form-fieldset">
                            <input
                                className="charities__form-input"
                                placeholder="First Name"
                                type="text"
                                required
                                name="firstName"
                                value={values.firstName}
                                onChange={updateValue}
                            />
                            <input
                                className="charities__form-input"
                                placeholder="Last Name"
                                type="text"
                                required
                                name="lastName"
                                value={values.lastName}
                                onChange={updateValue}
                            />
                        </div>
                        <input
                            className="charities__form-input"
                            placeholder="Phone Number"
                            type="tel"
                            required
                            name="number"
                            value={values.number}
                            onChange={updateValue}
                        />
                        <input
                            className="charities__form-input"
                            placeholder="Email Address"
                            type="email"
                            required
                            name="email"
                            value={values.email}
                            onChange={updateValue}
                        />
                        <p className="charities__form-error-message">
                            {message}
                        </p>
                        <button
                            disabled={disable}
                            type="submit"
                            className="charities__form-submit-button"
                        >
                            SUPPORT NOW
                            <i className="icon icon--white icon--arrow-right" />
                        </button>
                    </form>
                </div>
            </Container>
        </div>
    )
}

CharitiesForm.propTypes = {}

export { CharitiesForm }
