import React from 'react'
import PropTypes from 'prop-types'
import Img from 'gatsby-image'

import { Section } from '../Common'

const FeaturesCard = ({ title, description, image }) => {
    return (
        <Section className="features-card-repeater-col">
            
            <div className="feature-card-content">
            <div className="feature-card-thumbnail">
                <img src={image} className="" />
            </div>
                <h4 className="">{title}</h4>
                <div className="feature-card-para">{description}</div>
            </div>
        </Section>
    )
}

FeaturesCard.propTypes = {
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    image: PropTypes.string.isRequired,
}

export { FeaturesCard }
