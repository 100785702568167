import { graphql, useStaticQuery } from 'gatsby'

function useJoinTheTeam() {
    const data = useStaticQuery(
        graphql`
            query JoinTheTeamQuery {
                wpgraphql {
                    pageBy(uri: "join-the-team") {
                        title
                        acf_joinTheTeam {
                            mainTitle
                            mainDescription
                            form {
                                title
                                footermessage
                            }
                        }
                        acf_largeImageBlock {
                            boxtitle
                            title
                            description
                            image {
                                altText
                                sourceUrl
                                sourceUrlSharp {
                                    childImageSharp {
                                        fluid(quality: 100, maxWidth: 1250) {
                                            base64
                                            aspectRatio
                                            src
                                            srcSet
                                            sizes
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        `
    )
    return data.wpgraphql.pageBy
}

export { useJoinTheTeam }
