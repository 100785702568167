import { graphql, useStaticQuery } from 'gatsby'

function useAllPartners() {
    const data = useStaticQuery(
        graphql`
            query AllPartnersQuery {
                wpgraphql {
                    partners {
                        edges {
                            node {
                                acf_partners {
                                    logo {
                                        alt
                                        image {
                                            sourceUrl
                                            sourceUrlSharp {
                                                childImageSharp {
                                                    fluid(
                                                        pngQuality: 100
                                                        maxWidth: 175
                                                    ) {
                                                        base64
                                                        aspectRatio
                                                        src
                                                        srcSet
                                                        sizes
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        `
    )
    return data.wpgraphql.partners
}

export { useAllPartners }
