import { graphql, useStaticQuery } from 'gatsby'

function useCharities() {
    const data = useStaticQuery(
        graphql`
            query CharitiesQuery {
                wpgraphql {
                    pageBy(uri: "charities") {
                        title
                        acf_hero {
                            bbTitle
                            bbDescription
                            heroImage {
                                sourceUrl
                                sourceUrlSharp {
                                    childImageSharp {
                                        fluid(quality: 100, maxWidth: 1440) {
                                            base64
                                            aspectRatio
                                            src
                                            srcSet
                                            sizes
                                        }
                                    }
                                }
                            }
                        }
                        acf_charitiesContent {
                            mainTitle
                            mainDescription
                            form {
                                charitiesFormTitle
                                charitiesFormDescription
                                charitiesFormImage {
                                    altText
                                    sourceUrl
                                    sourceUrlSharp {
                                        childImageSharp {
                                            fluid(
                                                quality: 100
                                                maxWidth: 1250
                                            ) {
                                                base64
                                                aspectRatio
                                                src
                                                srcSet
                                                sizes
                                            }
                                        }
                                    }
                                }
                            }
                        }
                        acf_largeImageBlock {
                            isslider
                            posterimage {
                                altText
                                sourceUrl
                                sourceUrlSharp {
                                    childImageSharp {
                                        fluid(quality: 100, maxWidth: 1250) {
                                            base64
                                            aspectRatio
                                            src
                                            srcSet
                                            sizes
                                        }
                                    }
                                }
                            }
                            slider {
                                blockblueboxtitle
                                blockdescription
                                blocktitle
                                blockimage {
                                    altText
                                    sourceUrl
                                    sourceUrlSharp {
                                        childImageSharp {
                                            fluid(
                                                quality: 100
                                                maxWidth: 1250
                                            ) {
                                                base64
                                                aspectRatio
                                                src
                                                srcSet
                                                sizes
                                            }
                                        }
                                    }
                                }
                                blockvideo {
                                    mediaItemUrl
                                    sourceUrl
                                }
                                blockposterimage {
                                    altText
                                    altText
                                    sourceUrl
                                    sourceUrlSharp {
                                        childImageSharp {
                                            fluid(
                                                quality: 100
                                                maxWidth: 1250
                                            ) {
                                                base64
                                                aspectRatio
                                                src
                                                srcSet
                                                sizes
                                            }
                                        }
                                    }
                                }
                            }
                            boxtitle
                            title
                            description
                            image {
                                altText
                                sourceUrl
                                sourceUrlSharp {
                                    childImageSharp {
                                        fluid(quality: 100, maxWidth: 1250) {
                                            base64
                                            aspectRatio
                                            src
                                            srcSet
                                            sizes
                                        }
                                    }
                                }
                            }
                        }
                    }
                    supports {
                        edges {
                            node {
                                acf_charity {
                                    image {
                                        sourceUrl
                                        sourceUrlSharp {
                                            childImageSharp {
                                                fluid(pngQuality: 100) {
                                                    base64
                                                    aspectRatio
                                                    src
                                                    srcSet
                                                    sizes
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        `
    )
    return data.wpgraphql.pageBy
}

export { useCharities }
