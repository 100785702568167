import React, { useState } from 'react'
import PropTypes from 'prop-types'
import useForm from '../../utils/useForm'
import { navigate } from 'gatsby'

const ContactUsForm = props => {
    const [disable, setDisable] = useState(false)
    const [message, setMessage] = useState('')
    const { values, updateValue, resetValues } = useForm({
        name: '',
        number: '',
        companyName: '',
        email: '',
        industry: '',
        employees: '',
        message: '',
    })

    const handleSubmit = async e => {
        e.preventDefault()
        setDisable(true)
        const body = {
            name: values.name,
            number: values.number,
            companyName: values.companyName,
            email: values.email,
            industry: values.industry,
            employees: values.employees,
            message: values.message,
        }
        const res = await fetch(
            `${process.env.GATSBY_EMAIL_HANDLER_URL}/api/contact-form`,
            {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(body),
            }
        )
        // check if everything worked
        if (res.status >= 400 && res.status < 600) {
            setDisable(false)
            setMessage(
                'Oops! Something went wrong. Please try again later or email us at info@tekfirm.com'
            )
        } else {
            resetValues()
            setDisable(false)
            navigate('/thank-you')
        }
    }

    return (
        <div className="hero__form-container">
            <div className="hero__form-title">
                <h1 className="headline headline--h1">
                    LET'S STAY
                    <br />
                    IN TOUCH
                </h1>
            </div>
            <form
                className="hero__form-controls grid__row grid__row--gap-1"
                onSubmit={handleSubmit}
            >
                <div className="grid__row grid__row--col-xxs-1 grid__row--col-sm-2 grid__row--gap-1">
                    <input
                        type="text"
                        className="hero__form-input"
                        name="name"
                        placeholder="NAME *"
                        required
                        onChange={updateValue}
                        value={values.name}
                    />

                    <input
                        type="tel"
                        className="hero__form-input"
                        placeholder="PHONE NUMBER *"
                        name="number"
                        required
                        onChange={updateValue}
                        value={values.number}
                    />

                    <input
                        type="email"
                        name="email"
                        className="hero__form-input"
                        placeholder="EMAIL *"
                        required
                        onChange={updateValue}
                        value={values.email}
                    />

                    <input
                        type="text"
                        className="hero__form-input"
                        placeholder="COMPANY NAME "
                        name="companyName"
                        onChange={updateValue}
                        value={values.companyName}
                    />
                </div>
                <div className="grid__row grid__row--col-1 grid__row--gap-1">
                    <div className="hero__form-select-wrapper">
                        <select
                            className="hero__form-select"
                            onChange={updateValue}
                            name="employees"
                            value={values.employees}
                        >
                            <option value selected hidden>
                                NUMBER OF EMPLOYEES
                            </option>
                            <option value="1-50">1-50</option>
                            <option value="50-100">50-100</option>
                            <option value="100-250">100-250</option>
                            <option value="250-500">250-500</option>
                            <option value="500+">500+</option>
                        </select>
                    </div>
                    <div className="hero__form-select-wrapper">
                        <select
                            className="hero__form-select"
                            id="industry"
                            name="industry"
                            onChange={updateValue}
                            value={values.industry}
                            required
                        >
                            <option value selected hidden>
                                INDUSTRY (SELECT ONE) *
                            </option>
                            <option value="Marketing">Marketing</option>
                            <option value="Finance">Finance</option>
                            <option value="Construction">Construction</option>
                            <option value="Technology">Technology</option>
                            <option value="Retail">Retail</option>
                            <option value="Health">Health</option>
                            <option value="Advertising">Advertising</option>
                            <option value="Real-Estate">Real Estate</option>
                            <option value="Sales">Sales</option>
                            <option value="Other">Other</option>
                        </select>
                    </div>

                    <textarea
                        className="mdc-text-field__input hero__form-input hero__form-input--large"
                        aria-label="Label"
                        placeholder="ENTER YOUR MESSAGE *"
                        name="message"
                        onChange={updateValue}
                        value={values.message}
                        required
                        defaultValue={''}
                    />
                </div>
                <span className="hero__form-mandatory">*MANDATORY FIELDS</span>
                <span className='hero__form-contactdisclaimers'>By providing my phone number to “Tekfirm”,  I agree and acknowledge that “Tekfirm” may send text messages to my wireless phone number for any purpose. Message and data rates may apply. Message frequency will vary, and you will be able to Opt-out by replying “STOP”. For more information on how your data will be handled, please visit <a href='/privacy-policy'>PRIVACY POLICY</a></span>
                <button
                    type="submit"
                    disabled={disable}
                    className="book-section__info-button hero__form-button"
                >
                    CONTACT US
                    <i className="icon icon--white icon--arrow-right" />
                </button>
                <p className="hero__form-error">{message}</p>
            </form>
        </div>
    )
}

ContactUsForm.propTypes = {}

export { ContactUsForm }
