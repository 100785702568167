import React from 'react'
import PropTypes from 'prop-types'

const Section = ({ className, children, dark }) => {
    const classes = {
        extra: className ? className : '',
        dark: dark ? 'section--dark' : '',
    }
    return (
        <section className={`section ${classes.extra} ${classes.dark}`}>
            {children}
        </section>
    )
}

Section.propTypes = {
    children: PropTypes.node.isRequired,
    dark: PropTypes.bool,
    className: PropTypes.string,
}

export { Section }
