import { graphql, useStaticQuery } from 'gatsby'

function useCybersecurityCompliance() {
    const data = useStaticQuery(
        graphql`
            query CybersecurityComplianceQuery {
                wpgraphql {
                    pageBy(uri: "cybersecurity-compliance") {
                        acf_hero {
                            bbTitle
                            bbDescription
                            heroImage {
                                altText
                                sourceUrl
                                sourceUrlSharp {
                                    childImageSharp {
                                        fluid(quality: 100, maxWidth: 1440) {
                                            base64
                                            aspectRatio
                                            src
                                            srcSet
                                            sizes
                                        }
                                    }
                                }
                            }
                        }
                        acf_cybersecurity_compliance_content {
                            mainTitle
                            mainDescription
                            features {
                                description
                                title
                                icon {
                                    sourceUrl
                                    sourceUrlSharp {
                                        childImageSharp {
                                            fluid {
                                                base64
                                                aspectRatio
                                                src
                                                srcSet
                                                sizes
                                            }
                                        }
                                    }
                                }
                            }
                            benefits {
                                title
                                description
                                sectionImage {
                                    altText
                                    sourceUrl
                                    sourceUrlSharp {
                                        childImageSharp {
                                            fluid(
                                                quality: 100
                                                maxWidth: 1440
                                            ) {
                                                base64
                                                aspectRatio
                                                src
                                                srcSet
                                                sizes
                                            }
                                        }
                                    }
                                }
                            }
                        }
                        acf_testimonial_title_rating {
                            testimonialTitle
                        }
                        acf_testimonials {
                            cards {
                                name
                                position
                                description
                                profilePhoto {
                                    sourceUrl
                                }
                            }
                        }
                        acf_schedule_free_consultation_content {
                            mainTitle
                            subTitle
                            description
                            sectionImage {
                                altText
                                sourceUrl
                                sourceUrlSharp {
                                    childImageSharp {
                                        fluid(quality: 100, maxWidth: 1440) {
                                            base64
                                            aspectRatio
                                            src
                                            srcSet
                                            sizes
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        `
    )
    return data.wpgraphql.pageBy
}

export { useCybersecurityCompliance }
