import { graphql, useStaticQuery } from 'gatsby'

function useHomePage() {
    const data = useStaticQuery(
        graphql`
            query HomePageQuery {
                wpgraphql {
                    pageBy(uri: "home") {
                        title
                        acf_testimonials {
                            cards {
                                description
                                name
                                position
                            }
                        }
                        acf_largeImageBlock {
                            boxtitle
                            title
                            description
                            image {
                                sourceUrl
                                sourceUrlSharp {
                                    childImageSharp {
                                        fluid(quality: 100, maxWidth: 1250) {
                                            base64
                                            aspectRatio
                                            src
                                            srcSet
                                            sizes
                                        }
                                    }
                                }
                            }
                        }
                        acf_hero {
                            hasslider
                            bbDescription
                            bbTitle
                            sliderImages {
                                image {
                                    altText
                                    sourceUrl
                                    sourceUrlSharp {
                                        childImageSharp {
                                            fluid(
                                                quality: 100
                                                maxWidth: 1440
                                            ) {
                                                base64
                                                aspectRatio
                                                src
                                                srcSet
                                                sizes
                                            }
                                        }
                                    }
                                }
                            }
                        }
                        acf_connectForm {
                            connectTitle
                            connectDescription
                            connectImage {
                                sourceUrl
                                sourceUrlSharp {
                                    childImageSharp {
                                        fluid(quality: 100, maxWidth: 1440) {
                                            base64
                                            aspectRatio
                                            src
                                            srcSet
                                            sizes
                                        }
                                    }
                                }
                            }
                        }
                    }
                    services {
                        edges {
                            node {
                                title
                                slug
                                acf_homeServiceCard {
                                    cardTitle
                                    cardDescription
                                    cardImage {
                                        altText
                                        sourceUrl
                                        sourceUrlSharp {
                                            childImageSharp {
                                                fluid(
                                                    pngQuality: 100
                                                    maxWidth: 150
                                                ) {
                                                    base64
                                                    aspectRatio
                                                    src
                                                    srcSet
                                                    sizes
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        `
    )
    return data.wpgraphql
}

export { useHomePage }
