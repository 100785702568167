import React from 'react'
import PropTypes from 'prop-types'
import { Section } from '.'
import { ContactUsForm } from '../FormSections'
import htmr from 'htmr'
import Img from 'gatsby-image'

const ConsultationContent = ({ title, subTitle, description, image }) => {
    return (
        <Section className="consultation-content-form-section">
            <div className='container'>
                <div className="consultation-form-content-row">
                <div className="consultation-content-col">
                        <h3>{title}</h3>
                        <h4>{subTitle}</h4>
                        <div className="consultation-para">
                            {htmr(description)}
                        </div>
                       
                    </div>
                <div className="consultation-form-col">
                    
                    <Img fluid={image} className="" alt="img" />
                    <div className="common-form-style form-parent-sec">
                        <h4>Protect Your Firm With Expert Partners followed by the form</h4>
                            <ContactUsForm />
                        </div>
                </div>
                    
                    
                
               
                    </div>
            </div>
        </Section>
    )
}

ConsultationContent.propTypes = {
    title: PropTypes.string.isRequired,
    subTitle: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
}

export { ConsultationContent }
