import { graphql, useStaticQuery } from 'gatsby'

function usePartners() {
    const data = useStaticQuery(
        graphql`
            query PartnersQuery {
                wpgraphql {
                    pageBy(uri: "partners") {
                        title
                        acf_hero {
                            bbTitle
                            bbDescription
                            heroImage {
                                altText
                                sourceUrl
                                sourceUrlSharp {
                                    childImageSharp {
                                        fluid(quality: 100, maxWidth: 1440) {
                                            base64
                                            aspectRatio
                                            src
                                            srcSet
                                            sizes
                                        }
                                    }
                                }
                            }
                        }
                        acf_bookCTA {
                            title
                            url {
                                url
                            }
                            image {
                                altText
                                sourceUrl
                                sourceUrlSharp {
                                    childImageSharp {
                                        fluid(quality: 100, maxWidth: 1200) {
                                            base64
                                            aspectRatio
                                            src
                                            srcSet
                                            sizes
                                        }
                                    }
                                }
                            }
                        }
                        acf_partnersContent {
                            mainTitle
                            mainDescription
                        }
                    }
                    partners {
                        edges {
                            node {
                                acf_partners {
                                    logo {
                                        image {
                                            sourceUrl
                                            sourceUrlSharp {
                                                childImageSharp {
                                                    fluid(pngQuality: 100) {
                                                        base64
                                                        aspectRatio
                                                        src
                                                        srcSet
                                                        sizes
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        `
    )
    return data.wpgraphql.pageBy
}

export { usePartners }
