import React from 'react'
import PropTypes from 'prop-types'
import htmr from 'htmr'

import { Container, Section } from '../Common'

const ContentHeader = ({ title, description, small = true }) => {
    return (
        <Section className="top-description-section">
            <Container small={small}>
                <div className="description-block">
                    <h1 className="description-block__title">{htmr(title)}</h1>
                    <div className="description-block__description">
                        <p className="paragraph paragraph--lg paragraph--dark">
                            {htmr(description)}
                        </p>
                    </div>
                </div>
            </Container>
        </Section>
    )
}

ContentHeader.propTypes = {
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
}

export { ContentHeader }
