import React from 'react'
import PropTypes from 'prop-types'

const SectionHeader = ({ children, className }) => {
    return (
        <>
            <div className={`section__header ${className ? className : ''}`}>
                <h1 className="headline headline--h2">{children}</h1>
            </div>
            <div className="divider divider--thin" />
        </>
    )
}

SectionHeader.propTypes = {}

export { SectionHeader }
