import React from 'react'
import PropTypes from 'prop-types'

const FooterSocial = ({ twitter, linkedIn, instagram, facebook }) => {
    return (
        <div className="footer__social">
            <a href={instagram} rel="noreferrer" target="_blank">
                <div className="icon icon--md icon--white icon--squared-fill icon--instagram" />
            </a>
            <a href={linkedIn} rel="noreferrer" target="_blank">
                <div className="icon icon--md icon--white icon--squared-fill icon--linkedin" />
            </a>
            <a href={twitter} rel="noreferrer" target="_blank">
                <div className="icon icon--md icon--white icon--squared-fill icon--twitter" />
            </a>
            <a href={facebook} rel="noreferrer" target="_blank">
                <div className="icon icon--md icon--white icon--squared-fill icon--facebook" />
            </a>
        </div>
    )
}

FooterSocial.propTypes = {
    twitter: PropTypes.string.isRequired,
    linkedIn: PropTypes.string.isRequired,
    instagram: PropTypes.string.isRequired,
    facebook: PropTypes.string.isRequired,
}

export default FooterSocial
