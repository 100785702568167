import { graphql, useStaticQuery } from 'gatsby'

function usePrivacyPolicy() {
    const data = useStaticQuery(
        graphql`
            query PrivacyPolicyQuery {
                wpgraphql {
                    pageBy(uri: "privacy-policy") {
                        title
                        acf_privacyPolicy {
                            policyContent
                        }
                    }
                }
            }
        `
    )
    return data.wpgraphql.pageBy
}

export { usePrivacyPolicy }
