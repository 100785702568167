import { graphql, useStaticQuery } from 'gatsby'

function useMeetTheTeam() {
    const data = useStaticQuery(
        graphql`
            query MeetTheTeamQuery {
                wpgraphql {
                    pageBy(uri: "meet-the-team") {
                        title
                        acf_meetTheTeam {
                            mainTitle
                            mainDescription
                            about {
                                bbtitle
                                description
                                title
                            }
                        }
                        acf_bookCTA {
                            title
                            url {
                                url
                            }
                            image {
                                altText
                                sourceUrl
                                sourceUrlSharp {
                                    childImageSharp {
                                        fluid(quality: 100, maxWidth: 1200) {
                                            base64
                                            aspectRatio
                                            src
                                            srcSet
                                            sizes
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        `
    )
    return data.wpgraphql.pageBy
}

export { useMeetTheTeam }
