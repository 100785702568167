import React from 'react'
import { Container, Section } from '../Common'

const PageHeader = ({ title, description }) => {
    return (
        <Section>
            <Container compact>
                <div className="page-header">
                    <div className="page-header__title">
                        <h1 className="headline headline--h1">{title}</h1>
                        {!description && (
                            <div className="icon icon--arrow-down icon--dark"></div>
                        )}
                    </div>
                    {description && (
                        <div className="page-header__description">
                            <p className="paragraph paragraph--md paragraph--dark paragraph--bold">
                                {description}
                            </p>
                        </div>
                    )}
                </div>
            </Container>
        </Section>
    )
}

export { PageHeader }
