import React from 'react'
import PropTypes from 'prop-types'
import Nav from './Nav/Nav'
import Footer from './Footer/Footer'
import { StickyFooter } from './StickyFooter'
import { Link } from 'gatsby'

import '../scss/styles.scss'

const Layout = ({ children, pageClass, noHero }) => {
    const classes = {
        name: pageClass ? pageClass : '',
    }
    return (
        <>
            <Nav noHero={noHero} />
            <main className={classes.name}>{children}</main>
            <StickyFooter>
                <Link
                    to="/book-consultation"
                    className="connect-section__card-button"
                >
                    BOOK CONSULTATION
                </Link>
            </StickyFooter>
            <Footer />
        </>
    )
}

Layout.propTypes = {
    children: PropTypes.node.isRequired,
    pageClassName: PropTypes.string,
}

export default Layout
