import React, { useState } from 'react'
import PropTypes from 'prop-types'
import Img from 'gatsby-image'
import addToMailchimp from 'gatsby-plugin-mailchimp'
import { Container } from '../Common'
import useForm from '../../utils/useForm'
const LetsConnectForm = ({ title, description, image }) => {
    const [result, setResult] = useState('')
    const [disable, setDisable] = useState(false)
    const [message, setMessage] = useState('')
    const { values, updateValue, resetValues } = useForm({ EMAIL: '' }) //Keep Uppercased for MailChimp.

    const handleSubmit = async e => {
        e.preventDefault()
        setResult('')
        setDisable(true)
        const result = await addToMailchimp(values.EMAIL)
        messageHandler(result)
        resetValues()
        setResult(result)
        setDisable(false)
    }

    const messageHandler = result => {
        if (result.result === 'error') {
            setMessage('Please try again later or use a different email.')
        }
        if (result.result === 'success') {
            setMessage('You are now subscribed.')
        }
    }

    return (
        <div className="connect-section">
            <Img
                fluid={image}
                alt={'background'}
                className="connect-section-image"
            />
            <Container>
                <div className="connect-section__card">
                    <div className="connect-section__card-header">{title}</div>
                    <form
                        className="connect-section__card-form"
                        onSubmit={handleSubmit}
                    >
                        <input
                            type="email"
                            placeholder="ENTER YOUR EMAIL"
                            required
                            name="EMAIL"
                            value={values.EMAIL}
                            onChange={updateValue}
                            className="connect-section__card-input"
                        />
                        <span className="connect-section__card-message">
                            {message ? message : description}
                        </span>
                        <button
                            type="submit"
                            disabled={disable}
                            className="connect-section__card-button"
                        >
                            CONNECT ME
                            <div className="icon icon--arrow-right icon--white" />
                        </button>
                    </form>
                </div>
            </Container>
        </div>
    )
}

LetsConnectForm.propTypes = {}

export { LetsConnectForm }
