import { graphql, useStaticQuery } from 'gatsby'

function useBlogPosts() {
    const data = useStaticQuery(
        graphql`
            query BlogPostsQuery {
                wpgraphql {
                    mostPopular: tags(where: { slug: "most-popular" }) {
                        edges {
                            node {
                                posts(first: 4) {
                                    edges {
                                        node {
                                            title
                                            slug
                                            uri
                                            acf_blogPostMeta {
                                                category {
                                                    name
                                                    slug
                                                }
                                            }
                                            acf_blog {
                                                blogImage {
                                                    altText
                                                    sourceUrl
                                                    sourceUrlSharp {
                                                        childImageSharp {
                                                            fluid(
                                                                quality: 100
                                                                maxWidth: 1440
                                                            ) {
                                                                base64
                                                                aspectRatio
                                                                src
                                                                srcSet
                                                                sizes
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                    blogPosts: posts {
                        edges {
                            node {
                                acf_blog {
                                    blogContent
                                    blogExcerpt
                                    blogImage {
                                        altText
                                        sourceUrl
                                        sourceUrlSharp {
                                            childImageSharp {
                                                fluid(
                                                    quality: 100
                                                    maxWidth: 1440
                                                ) {
                                                    base64
                                                    aspectRatio
                                                    src
                                                    srcSet
                                                    sizes
                                                }
                                            }
                                        }
                                    }
                                }
                                id
                                title
                                slug
                                acf_blogPostMeta {
                                    category {
                                        name
                                        slug
                                    }
                                }
                            }
                        }
                    }
                    categories: categories(where: { hideEmpty: true }) {
                        edges {
                            node {
                                slug
                                name
                            }
                        }
                    }
                }
            }
        `
    )
    return data.wpgraphql
}

export { useBlogPosts }
