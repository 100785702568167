import { graphql, useStaticQuery } from 'gatsby'

function useCompanyDetails() {
    const data = useStaticQuery(
        graphql`
            query comapnyDetailsQuery {
                wpgraphql {
                    companyDetails {
                        acf_companyDetails {
                            email
                            address {
                                line1
                                line2
                            }
                            phone
                        }
                    }
                    social {
                        acf_social {
                            facebook
                            instagram
                            linkedin
                            twitter
                        }
                    }
                }
            }
        `
    )
    return {
        companyDetails: data.wpgraphql.companyDetails,
        social: data.wpgraphql.social,
    }
}

export { useCompanyDetails }
