import React from 'react'
import PropTypes from 'prop-types'
import Img from 'gatsby-image'
import { Section } from '.'

const Testimonial = ({ title, images }) => {
    return (
        <Section className="testimonails-head-wrap">
            <div className="testimonail-start-sec">
                {images.length > 0 &&
                    images.map(image => {
                        return (
                            <img
                                src={image.starImage.sourceUrl}
                                className=""
                                alt="rating icon"
                            />
                        )
                    })}
            </div>
            <h4 className="">{title}</h4>
        </Section>
    )
}

Testimonial.propTypes = {
    title: PropTypes.string.isRequired,
    images: PropTypes.string.isRequired,
}

export { Testimonial }
