import React from 'react'
import { Link } from 'gatsby'
import FooterSocial from './FooterSocial'
import FooterContent from './FooterContent'
import { useCompanyDetails } from '../../GraphQL/Queries'

const Footer = () => {
    const data = useCompanyDetails()

    const { companyDetails, social } = data
    const { acf_social: links } = social

    return (
        <footer className="section">
            <div className="container">
                <div className="footer">
                    <FooterContent companyDetails={companyDetails} />
                    <FooterSocial
                        twitter={links.twitter ?? ''}
                        linkedIn={links.linkedIn ?? ''}
                        instagram={links.instagram ?? ''}
                        facebook={links.facebook ?? ''}
                    />
                    <div className="footer__privacy-policy">
                        <span>
                            ALL RIGHTS RESERVED ® 2020 • {''}
                            <Link to="/privacy-policy">PRIVACY POLICY</Link>
                        </span>
                    </div>
                </div>
            </div>
        </footer>
    )
}

export default Footer
